<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 pt-3 pb-3">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control"
              v-model="searchData"
              :placeholder="$t('common.search')"
              v-on:keyup.enter="onSearchNotification()"
            />
          </div>
          <b-button
            variant="success"
            size="sm"
            class="send-reason-absence float-left pl-4 pr-4"
            @click="onSearchNotification()"
            >{{ $t("common.search") }}</b-button
          >
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">{{ $t("common.nonumber") }}</th>
              <th scope="col">{{ $t("notification.notificationTitle") }}</th>
              <th scope="col">{{ $t("notification.notificationSubTitle") }}</th>
              <th scope="col">
                {{ $t("notification.notificationContent") }}
              </th>
              <th scope="col">{{ $t("common.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in notificationList" :key="item + index">
              <td>{{ item.nonumber }}</td>
              <td class="text-left">{{ item.title }}</td>
              <td class="text-left">{{ item.sub_title }}</td>
              <td class="text-left">{{ item.content }}</td>
              <td>
                <b-button
                  type="button"
                  class="btn btn-danger"
                  @click="onShowModalConfirmDeleteNotification(item)"
                  size="sm"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          v-if="notificationList.length > 0"
          :rows="notificationPaginate.total"
          :per-page="notificationPaginate.perPage"
          :current-page="notificationPaginate.currentPage"
          :total="notificationPaginate.totalItem"
          @onPageChange="onNotificationPageChange"
        ></pagination>
      </div>
    </div>
    <confirm-modal
      ref="confirmDeleteNotificationModal"
      :content="confirmDeleteModalContent"
      @onConfirm="onClickDeleteNotification()"
    ></confirm-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "../../components/ConfirmModal";
export default {
  components: {
    "confirm-modal": ConfirmModal,
  },
  data: () => ({
    notificationList: [],
    searchData: null,
    notificationSelected: null,
    confirmDeleteModalContent: "notification.deleteNotificationContent",
    notificationPaginate: {
      total: 1,
      perPage: 1,
      currentPage: 1,
      totalItem: 0,
    },
  }),
  watch: {
    GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA: function () {
      this.notificationPaginate.total = this.GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA.paginate.totalPage;
      this.notificationPaginate.perPage = 1;
      this.notificationPaginate.currentPage = this.GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA.paginate.currentPage;
      this.notificationPaginate.totalItem =  this.GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA.paginate.total;
      this.notificationList = [];
      this.GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA.results.forEach((data, index) => {
        let nonumber = index + 1;
        let title = data.notification_data.title;
        let sub_title = data.notification_data.sub_title;
        let content = data.notification_data.body;
        let item = {
          nonumber: nonumber,
          title: title,
          sub_title: sub_title,
          content: content,
          id: data.id,
        };
        this.notificationList.push(item);
      });
    },
  },
  computed: {
    ...mapGetters(["GET_ALL_NOTIFICATION_FOR_USER_LIST_DATA"]),
  },
  methods: {
    onSearchNotification: function () {
      let filter = {
        params: { 
          search: this.searchData,
          page: 1
        },
      };
      this.GET_ALL_NOTIFICATION_FOR_USER_LIST(filter);
    },
    onShowModalConfirmDeleteNotification: function (data) {
      this.notificationSelected = data;
      this.$refs.confirmDeleteNotificationModal.onShowModalConfirm();
    },
    onClickDeleteNotification: function () {
      this.DELETE_NOTIFICATION_FOR_USER(this.notificationSelected)
        .then(
          function () {
            let filter = {
              params: {
                search: this.searchData,
                page: 1
              },
            };
            this.GET_ALL_NOTIFICATION_FOR_USER_LIST(filter);
            let filterUser = {
              params: {
                unread_params: true,
              }
            }
            this.GET_NOTIFICATION_FOR_USER(filterUser);
          }.bind(this)
        )
        .catch(
          function (error) {
            let message = error.response.data.message;
            this.ON_SHOW_TOAST({
              message: message,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    onNotificationPageChange: function (page) {
      this.notificationPaginate.currentPage = page;
      const customer_data = {
        params: {
          page: this.notificationPaginate.currentPage,
          search: this.searchData,
        },
      };
      this.GET_ALL_NOTIFICATION_FOR_USER_LIST(customer_data);
    },
    ...mapActions([
      "GET_ALL_NOTIFICATION_FOR_USER_LIST",
      "DELETE_NOTIFICATION_FOR_USER",
      "GET_NOTIFICATION_FOR_USER"
    ]),
  },
  mounted() {
    let filter = {
      params: {
        page_per: this.notificationPaginate.page_per,
        page: 1
      },
    };
    this.GET_ALL_NOTIFICATION_FOR_USER_LIST(filter);
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/color.scss";
tr td:last-child {
  width: 8%;
  white-space: nowrap;
}
tr td:first-child {
  width: 1%;
  white-space: nowrap;
}
.send-reason-absence {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
</style>